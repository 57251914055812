<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left">Score</th>
        </tr>
      </thead>
      <tbody>
        <tr class="secondary">
          <td>TOTAL POINTS</td>
          <td class="font-weight-bold">{{scores.leaderboard_score.total_score}} pts</td>
        </tr>
        <tr class="secondary-1">
          <td>CHAT POINTS</td>
          <td class="caption">
            {{scores.chat_points_count}} pts
            <v-btn x-small icon right @click="reset(scores.id)">
              <v-icon small>mdi-reload</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr v-for="item in scores.booth_scores" :key="item.id" >
          <td>
            {{ item.booth_name }} 
            <v-icon :color="
              item.booth_type == 'GOLD' ? 'warning' 
              : item.booth_type == 'SILVER' ? '#999' 
              : 'brown' 
            ">
              mdi-circle-medium
            </v-icon>
          </td>
          <td class="caption">{{ item.score }} pts</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ['scores'],
  methods: {
    ...mapActions('admin', [
      'resetStudentChat'        
    ]),
    reset(id) {
      console.log(id);
      this.resetStudentChat(id)
    }
  }
}
</script>

<style>

</style>