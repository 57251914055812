<template>
  <section>
    <div class="d-flex align-center ma-5">
      <v-btn icon :to="{name: 'Admin Gamification'}">
        <v-icon large>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <div class="display-1 font-weight-bold ml-5">
      Leaderboard
    </div>
    </div>
    
    <v-row >
      <v-col cols="12" xl="6" lg="8">  
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mx-2"
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="leaderboards.candidates"
          :items-per-page="10"
          :search="search"
        >
          <template v-slot:[`item.index`]="{ item }">
            <div class="caption">{{ leaderboards.candidates.indexOf(item) + 1 }}</div>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <div class="" v-if="item.user">
              <v-avatar v-if="!item.user.image" size="35" color="secondary"/>
              <v-avatar v-else size="35" color="secondary">
                <v-img :src="`${item.user.aws}/users/${item.user.uuid}/image/${item.user.image.image}`"></v-img>
              </v-avatar>
            </div>
            
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div v-if="item.user" class="">
              <small>{{$helpers.level(item.user.level)}}</small>
            </div>
            
          </template>
           <template v-slot:[`item.points`]="{ item }">
            <div class="">
              {{item.total_score}} pts
            </div>
          </template>
           <template v-slot:[`item.action`]="{ item }">
            <v-btn v-if="item.user" icon small @click="view(item.user.id)">
              <v-icon small >
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table> 

      </v-col>
      <v-col cols="12" xl="6" lg="4">
        <div v-if="show">
          <v-sheet class="col-xl-6 col-lg-8">
            <div class="d-flex flex-column justify-center align-center my-10">  
              <div class="">
                <v-avatar v-if="!scores.image" size="120" color="secondary"/>
                <v-avatar v-else size="120" color="secondary">
                  <v-img :src="`${scores.aws}/users/${scores.uuid}/image/${scores.image.image}`"></v-img>
                </v-avatar>
              </div>
              <div class="text-h5 font-weight-bold text-center mt-5">
                {{scores.firstname + ' ' + scores.lastname}}
                <div class="body-2 grey--text">{{$helpers.level(scores.level)}}</div>
              </div>
            </div>
            <div class="text-center d-flex justify-center">
              <v-btn outlined color="red" @click="disqualify = true"> 
                Mark as disqualied
              </v-btn>
              <v-btn text  @click="backendSponsors()"> 
                Export Chats
              </v-btn>
            </div>
            <div class="mx-5 mt-10" v-if="disqualify">
              <label class="caption">REASON FOR DISQUALIFICATION</label>
              <v-textarea rows="3" outlined hide-details v-model="reason"/>
              <div class="d-flex justify-end">
                <v-btn @click="eliminate" class="mt-1" text color="success">SUBMIT</v-btn>
                <v-btn class="mt-1" text @click="disqualify=false">Cancel</v-btn>
              </div>
              
            </div>
            <StudentScore :scores="scores"/>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-dialog max-width="450" v-model="dialog">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center success white--text" >
          <div class="">Export Chats</div>
          <v-btn icon small @click="dialog=false">
            <v-icon color="white" size="18">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-data-table
            v-model="selected"
            :headers="sponsor_headers"
            :items="sponsors"
            :items-per-page="5"
            show-select
            checkbox-color="success"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div class="d-flex flex-column">
                <div class="">
                  {{ `${item.firstname} ${item.lastname}` }}
                </div>
                <div class="caption">
                  {{ `${item.email}` }}
                </div>
              </div>
              
            </template>
          </v-data-table>
          <v-btn block color="info" outlined class="my-1" @click="exportNow">Export</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import StudentScore from '../components/result/StudentScoreTable.vue'
var CryptoJS = require("crypto-js");


export default {
  components: {
    StudentScore
  },
  data: () => ({
    show: false,
    disqualify: false,
    reason: '',
    search: '',
    dialog: false,
    selected: [],
    headers: [
      { text: "", align: "start", value: "index",},
      { text: "Image", value: "image" },
      { text: "First Name", value: "user.firstname" },
      { text: "Last Name", value: "user.lastname" },
      { text: "Type", value: "type" },
      { text: "Points", value: "points" },
      { text: "", value: "action" },
    ],
    sponsor_headers: [
      // { text: "Image", value: "image" },
      { text: "Name", value: "name" },
      // { text: "Type", value: "type" },
      // { text: "Points", value: "points" },
      // { text: "", value: "action" },
    ],
  }),
  computed: {
    ...mapState('admin', {
      leaderboards: (state) => state.leaderboards,
      scores: (state) => state.scores,
      sponsors: (state) => state.sponsors,
    })
  },
  mounted() {
    this.getLeaderboard()
  },
  methods: {
    ...mapActions('admin', [
      'showLeaderboard',
      'showStudentScores',
      'disqualifiedLeaderboard',
      'getSponsors',
      'exportChats'
    ]),

    view(id) {  
      this.showStudentScores(id)
      .then(() => { this.show = true })
      
    },

    getLeaderboard() {
      let key = CryptoJS.AES.decrypt(this.$route.params.id, 'secret')
      this.showLeaderboard(key.toString(CryptoJS.enc.Utf8))
    },

    eliminate() {
      if(confirm('Are you sure you want to eliminate this user in the leaderboard?\nNote user will be deleted in the leaderboard list')) {
        this.disqualifiedLeaderboard({
          id: this.scores.id,
          reason: this.reason
        }).then(() => {
          this.show = false
          this.search = ''
          this.getLeaderboard()
        })
      } 
    },

    backendSponsors() {
      this.dialog = true
      this.getSponsors()
    },

    exportNow() {
      this.exportChats({
        uuid: this.scores.uuid,
        sponsors: this.selected
      })
    }
  }
}
</script>